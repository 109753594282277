var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.inventory,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":[10, 20, 50, 100],"footer-props":{
  'items-per-page-options': [10, 20, 50, 100]
}},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.purchased_at ? _vm.formatDate(item.purchased_at) : "-")+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCostInPence(item.purchase_price, item.currency))+" ")]}},{key:"item.soldAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sale_price ? _vm.formatCostInPence(item.sale_price, item.currency) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":"","location":"bottom","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background":"#eee"},attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-2 view-pdf",attrs:{"small":""}},[_vm._v("mdi-menu-down")]),_vm._v(" Invoices ")],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.loadInvoice(item)}}},[_vm._v(" Purchase Invoice PDF ")])],1)],1),(item.status == 3)?_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.loadInvoice(item, 'reverse')}}},[_vm._v(" Reverse Invoice PDF ")])],1)],1):_vm._e()],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])}),_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }