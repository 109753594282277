<template>
  <div>
    <!-- Do some loading thing here -->
    <div class="loading" v-if="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <!-- / Do some loading thing here -->

    <!-- No accounts -->
    <div v-if="!isLoading && accounts.length == 0">
      <no-accounts></no-accounts>
    </div>
    <!-- / No acccounts -->
    <v-row cols="12" class="mx-0">
      <v-tabs v-model="selected_tab" v-if="!isLoading && accounts.length">
        <v-tab v-for="account in accounts" :key="account.id">{{ account.currency }}<span class="pl-2 d-none d-sm-block">Account</span></v-tab>
      </v-tabs>
    </v-row>
    <!-- Account Details -->
    <div class="account-details" v-if="!isLoading && selectedAccount" :key="`account-details-${selected_tab}`">
      <v-container fluid>
        <div class="welcome">
          <div class="left">
            <h1>
              {{ selectedAccount.currency }} Account
              <!-- (Account Number {{ selectedAccount.id }}) -->
            </h1>
            <!-- <p>
              Premium Account
            </p> -->
          </div>
          <div class="right"></div>
        </div>

        <div class="nothing-to-see" v-if="nothingToSee">
          <v-alert type="info">Nothing to see here! We'll send you offers by email shortly.</v-alert>
        </div>
        <v-row v-if="!nothingToSee" class="bg-white border mx-1 mb-3">
          <v-row v-if="!nothingToSee">

            <!-- Return on Investment -->
            <v-col cols="12" lg="4">
              <v-row>
                <v-col rows="12" lg="6" class="py-0">
                  <base-card class="px-4 py-4 mb-5">
                    <!-- <p class="mb-2">Total Profit</p> -->
                    <p class="mb-2">Total Sales</p>
                    <div class="text-h5 ma-0">
                      {{
                        formatCostInPence(
                          selectedAccount.total_bag_sales,
                          selectedAccount.currency,
                        )
                      }}
                    </div>
                  </base-card>

                  <base-card class="px-4 py-4 mb-5">
                    <!-- <p class="mb-2">Total Profit</p> -->
                    <p class="mb-2">Total Commission</p>
                    <div class="text-h5 ma-0">
                      {{
                        formatCostInPence(
                          (selectedAccount.total_bag_sales - selectedAccount.total_purchase_price_of_bags_sold) / 2,
                          selectedAccount.currency,
                        )
                      }}
                    </div>
                  </base-card>
                </v-col>
                <v-col rows="12" lg="6" class="py-0">
                  <base-card class="px-4 py-4 mb-5">
                    <!-- <p class="mb-2">Total Profit</p> -->
                    <p class="mb-2">Total Cost Price of Sales</p>
                    <div class="text-h5 ma-0">
                      {{
                        formatCostInPence(
                          selectedAccount.total_purchase_price_of_bags_sold,
                          selectedAccount.currency,
                        )
                      }}
                    </div>
                  </base-card>


                  <base-card class="px-4 py-4 mb-5">
                    <p class="mb-2">Total Current Stock Value</p>
                    <div class="text-h5 ma-0">
                      {{
                        formatCostInPence(
                          selectedAccount.current_inventory_balance,
                          selectedAccount.currency,
                        )
                      }}
                    </div>
                  </base-card>

                </v-col>
              </v-row>

              <base-card class="px-4 py-4">
                <p class="mb-2">Rate of Return (XIRR)</p>
                <investment-xirr :inventory="itemXirr"></investment-xirr>
              </base-card>
            </v-col>

            <!-- / Return on Investment -->

            <!-- Transactions -->
            <v-col cols="12" lg="8">
              <base-card class="px-4 py-4">
                <p class="mb-2">Account Transactions</p>
                <investment-table :inventory="itemSales"></investment-table>
              </base-card>
            </v-col>
            <!-- / Transactions -->
          </v-row>


        </v-row>

        <v-row>

          <!-- Current Inventory -->
          <v-col cols="12" md="6">
            <base-card class="px-4 py-4">
              <p class="mb-2">Current Inventory</p>
              <inventory-gallery :inventory="currentInventory"></inventory-gallery>
            </base-card>

            <div class="mt-5" v-if="currentInventoryHasProducts">
              <view-consignment-notice-button-and-modal :current-inventory="currentInventory"
                                                        :account="selectedAccount"></view-consignment-notice-button-and-modal>
            </div>

          <!-- / Current Inventory -->

          <!-- Past Inventory -->

            <base-card class="px-4 py-4">
              <p class="mb-2">Past Inventory</p>
              <inventory-gallery :inventory="pastInventory"></inventory-gallery>
            </base-card>
          </v-col>
          <!-- / Past Inventory -->

          <!-- Current Inventory -->
          <v-col cols="12" md="6">
            <base-card class="px-4 py-4">
              <p class="mb-2">Product Invoices</p>
              <invoices-table :inventory="productInvoices"></invoices-table>
            </base-card>

          </v-col>

        </v-row>
      </v-container>
    </div>
    <!-- / Account Details -->
  </div>
</template>

<script>
import InventoryGallery from '../../../components/gallery/InventoryGallery.vue';
import InvestmentTable from '../../../components/Account/InvestmentTable.vue';
import InvestmentXirr from '../../../components/Account/InvestmentXirr.vue';
import ViewConsignmentNoticeButtonAndModal from '../../../components/Account/ViewConsignmentNoticeButtonAndModal.vue';
import { mapGetters, mapActions } from 'vuex';
import Display from '../../../mixins/Display';
import NoAccounts from './Dashboard/NoAccounts.vue';
import Vue from 'vue';
import { map } from 'lodash';
import moment from 'moment/moment';
import { xirr, CashFlow } from '@webcarrot/xirr';
import InvoicesTable from "@/components/Account/InvoicesTable.vue";

const BLANK_GRID = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
const flows = [];
export default {

  name: 'Dashboard',

  mixins: [Display],

  components: { InvestmentTable, InvestmentXirr, InvoicesTable, InventoryGallery, ViewConsignmentNoticeButtonAndModal, NoAccounts },

  data() {
    return {
      // pastInventory: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
    };
  },

  created() {
    this.fetch();
  },

  computed: {
    ...mapGetters('accounts', {
      isLoading: 'isLoading',
      accounts: 'accounts',
      selectedAccountId: 'selectedAccountId',
      selectedAccount: 'selectedAccount',
    }),

    nothingToSee() {
      if (this.selectedAccount) {
        return (
          !this.currentInventoryHasProducts
          && !this.pastInventoryHasProducts
          && this.selectedAccount.total_profit == 0
        );
      }
      return false;
    },

    selected_tab: {
      get() {
        if (this.selectedAccount) {
          return this.accounts.indexOf(this.selectedAccount);
        }
        return null;
      },
      set(val) {
        let account = this.accounts[val];
        if (account) {
          this.selectAccountId(account.id);
        }
      },
    },

    currentInventoryHasProducts() {
      if (this.selectedAccount && this.selectedAccount.products) {
        let products = _.filter(this.selectedAccount.products, (product) => {
          return product.status !== 3;
        });
        return products.length > 0;
      }
      return false;
    },

    currentInventory() {
      if (this.selectedAccount && this.selectedAccount.products) {
        let products = _.filter(this.selectedAccount.products, (product) => {
          return product.status !== 3;
        });
        return products.length ? products : BLANK_GRID;
      }
      return BLANK_GRID;
    },

    itemSales() {
      if (this.selectedAccount && this.selectedAccount.products) {
        const stringify = JSON.stringify(this.selectedAccount.products);
        const purchases = JSON.parse(stringify);
        const sales = JSON.parse(stringify);

        for (let i = 0; i < purchases.length; i++) {
          purchases[i]['sold_at'] = '';
          purchases[i]['sale_price'] = '';
          purchases[i]['dateSort'] = purchases[i]['purchased_at'];
          purchases[i]['date'] = purchases[i]['purchased_at'].substring(0, 10).split("-").reverse().join("-");
          purchases[i]['type'] = 'Purchase';
          purchases[i]['purchase_amount'] = (purchases[i]['purchase_price'] / 100).toFixed(2);
        }

        const lines = _.filter(sales, (product) => {
          return product.status == 3;
        });

        for (let i = 0; i < lines.length; i++) {
          if(lines[i]['sold_at']) {
            lines[i]['purchased_at'] = '';
            lines[i]['date'] = lines[i]['sold_at'].substring(0, 10).split("-").reverse().join("-");
            lines[i]['type'] = 'Sale';
            lines[i]['dateSort'] = lines[i]['sold_at'];
            lines[i]['sales_amount'] = (lines[i]['purchase_price'] / 100 + lines[i]['consignor_profit'] / 100).toFixed(2);
            lines[i]['purchase_price'] = '';
          }
        }

        const transactions = purchases.concat(lines);
        const sortedTransactions = transactions.map(({ amount, date }) => ({ amount, date }));

        return transactions.length ? transactions : BLANK_GRID;
      }
    },

    itemXirr() {
      if (this.selectedAccount && this.selectedAccount.products) {
        const stringify = JSON.stringify(this.selectedAccount.products);
        const purchases = JSON.parse(stringify);
        const sales = JSON.parse(stringify);
        var roi = [];


        const linesP = purchases;
        for (let i = 0; i < linesP.length; i++) {
          linesP[i]['sold_at'] = '';
          linesP[i]['sale_price'] = '';
          linesP[i]['dateSort'] = linesP[i]['purchased_at'];
          linesP[i]['date'] = new Date(linesP[i]['purchased_at']);
          linesP[i]['type'] = 'Purchase';
          linesP[i]['amount'] = -(linesP[i]['purchase_price'] / 100);
        }

        const unsold = _.filter(purchases, (item) => {
          return item.status !== 3;
        });

        const unsoldStringify = JSON.stringify(unsold)
        const unsoldP = JSON.parse(unsoldStringify);
        for (let i = 0; i < unsoldP.length; i++) {
          unsoldP[i]['sold_at'] = '';
          unsoldP[i]['sale_price'] = '';
          unsoldP[i]['date'] = new Date();
          unsoldP[i]['type'] = 'Sale';
          unsoldP[i]['amount'] = (unsoldP[i]['purchase_price'] / 100);
        }

        const lines = _.filter(sales, (product) => {
          return product.status == 3;
        });
        for (let i = 0; i < lines.length; i++) {

          lines[i]['purchased_at'] = '';
          if (new Date(lines[i]['sold_at']) > "0") {
            lines[i]['date'] = new Date(lines[i]['sold_at'])
          } else {
            lines[i]['date'] = new Date();
          }
          lines[i]['type'] = 'Sale';
          lines[i]['dateSort'] = lines[i]['sold_at'];
          lines[i]['amount'] = (lines[i]['purchase_price'] / 100 + lines[i]['consignor_profit'] / 100);
          lines[i]['purchase_price'] = '';
        }

        const transactions = linesP.concat(lines).concat(unsoldP);
        const filteredTransactions = transactions.map(({ amount, date }) => ({ amount, date }));

        const flows =
          filteredTransactions;
        try {
          roi['xirr'] = xirr(flows);
          console.log(xirr(flows))
        } catch (err) {
          roi['xirr'] = '0';
        }
        roi['start'] = transactions[0]['dateSort'].substring(0, 10);
        roi['end'] = transactions.pop()['dateSort'].substring(0, 10);
      }
      return roi;
    },

    pastInventoryHasProducts() {
      if (this.selectedAccount && this.selectedAccount.products) {
        let products = _.filter(this.selectedAccount.products, (product) => {
          return product.status == 3;
        });
        return products.length > 0;
      }
      return false;
    },

    pastInventory() {
      if (this.selectedAccount && this.selectedAccount.products) {
        let products = _.filter(this.selectedAccount.products, (product) => {
          return product.status == 3;
        });
        return products.length ? products : BLANK_GRID;
      }
      return BLANK_GRID;
    },

    productInvoices() {
      if (this.selectedAccount && this.selectedAccount.products) {
        let products = _.filter(this.selectedAccount.products, (product) => {
          return product;
        });
        return products.length ? products : BLANK_GRID;
      }
      return BLANK_GRID;
    },



  },

  methods: {
    ...mapActions('accounts', {
      fetch: 'fetch',
      selectAccountId: 'selectAccountId',
    }),
  },
};
</script>
<style lang="scss" scoped>
.loading {
  text-align: center;
  height: 100%;
  padding-top: 100px;
}

.account-details {
  padding: 15px;

  .welcome {
    margin-bottom: 20px;
    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }
  }
}
</style>
