<template>
  <div>
    <v-data-table :headers="headers" :items="inventory" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                  :items-per-page="[10, 20, 50, 100]" :footer-props="{
    'items-per-page-options': [10, 20, 50, 100]
  }" >
      <template v-slot:item.date="{ item }">
        {{ item.purchased_at ? formatDate(item.purchased_at) : "-" }}
      </template>

      <template v-slot:item.amount="{ item }">
        {{  formatCostInPence(item.purchase_price, item.currency) }}

      </template>
      <template v-slot:item.soldAmount="{ item }">

        {{ item.sale_price ? formatCostInPence(item.sale_price, item.currency) : '' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu close-on-click location="bottom" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="background:#eee;" text v-bind="attrs" v-on="on">
              <v-icon small class="me-2 view-pdf">mdi-menu-down</v-icon>
              Invoices
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn text @click="loadInvoice(item)">
                  Purchase Invoice PDF
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status == 3">
              <v-list-item-title>
                <v-btn text @click="loadInvoice(item, 'reverse')">
                  Reverse Invoice PDF
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.name="{ item }">
        {{ item.name }}


      </template>
    </v-data-table>
    <div>

    </div>

  </div>
</template>

<script>
import Products from '../../mixins/Products';
import user from "../../api/investors";
import products from "../../api/products";
import {degrees, PDFDocument, rgb, StandardFonts, PDFFont} from 'pdf-lib';
import Dates from "../../mixins/Dates";
import Display from "../../mixins/Display";
import fontkit from '@pdf-lib/fontkit';
import axios from 'axios';
import investors from "@/api/investors";
import accounts from "@/api/accounts";
import moment from "moment/moment";

export default {
  props: ['inventory'],
  mixins: [Products, Dates, Display],

  data() {
    return {
      pagination: {
        itemsPerPage: 20,
      },
      // length: Math.ceil(this.inventory.length / PAGE_LENGTH),
      sortBy: 'dateSort',
      sortDesc: false,
      onboarding: 0,
      headers: [
        {
          text: 'Date',
          align: 'start',
          value: 'date',
        },
        {
          text: 'Item',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Purchase Amount (£)',
          value: 'amount',
          sortable: false,
        },
        {
          text: 'Sold Amount (£)',
          value: 'soldAmount',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },

        // {
        // text: "Balance",
        // value: "balance",
        // },

      ],
      pdfUrl: '/Invoice_template.pdf', // URL to the PDF with update sections
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    formatTime(date) {
      return new Date(date).getTime()
    },
    async loadInvoice(invoice, type = 'straightforward') {
      this.handleButtonClick(invoice, type);

    },

    async viewStraightforwardInvoicePdf(invoice, url) {

      // Fetch the PDF using axios.
      const response = await axios.get(url, {responseType: 'arraybuffer'});

      const existingPdfBytes = response.data;

      // Load the PDF using pdf-lib.
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      pdfDoc.registerFontkit(fontkit);

      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // get array buffer of font
      const fontUrl = '/CambriaFont.ttf'
      const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
      // embed array buffer of font into PDF
      const customFont = await pdfDoc.embedFont(fontBytes);

      // Modify the PDF.
      const firstPage = pdfDoc.getPages()[0];
      const {height} = firstPage.getSize();
      const date = this.invoiceDate(invoice.purchased_at)
      const total = this.formatCostInPence2dp(invoice.sale_price, invoice.currency)

      const investor = await accounts.get(this.inventory[0].account_id);
      const max_description_chars = 66;
      const margin_px = 10;


      firstPage.drawText(investor.data.user.name, {
        x: 61,
        y: 672,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.user.address_line_one, {
        x: 61,
        y: 655,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.user.city, {
        x: 61,
        y: 645,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.user.postcode.toUpperCase(), {
        x: 61,
        y: 635,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });


      firstPage.drawText(invoice.stock_number.toString(), {
        x: 450,
        y: 686,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(date.toString(), {
        x: 419,
        y: 662,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText('1', {
        x: 91,
        y: 526,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      const invoice_description = invoice.name.toString();
      const invoice_chunks = invoice_description.match(new RegExp('.{1,' + max_description_chars + '}', 'g'));

      let linues_counter = 0;
      for (const invoice_chunk of invoice_chunks) {
        firstPage.drawText(invoice_chunk.trim(), {
          x: 136,
          y: 526 - (margin_px * linues_counter),
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        linues_counter++;
      }

      firstPage.drawText(total.toString(), {
        x: 485,
        y: 526,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      // Total
      firstPage.drawText(total.toString(), {
        x: 485,
        y: 280,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      if(invoice.paid == 1){
        firstPage.drawText('PAID', {
          x: 135,
          y: height / 2 + 100,
          size: 150,
          font: customFont,
          color: rgb(0.8, 0.8, 0.8),
          opacity: 0.3,
          rotate: degrees(-45),
        })
      }



      // Save the modified PDF to a variable (`pdfBytes`).
      // Now, you can use `pdfBytes` for any additional operations (e.g. downloading the modified PDF).
      return await pdfDoc.save();
    },

    async viewReverseInvoicePdf(invoice, url) {
      // Fetch the PDF using axios.
      const response = await axios.get(url, {responseType: 'arraybuffer'});
      const existingPdfBytes = response.data;

      // Load the PDF using pdf-lib.
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);

      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      // get array buffer of font
      const fontUrl = '/CambriaFont.ttf'
      const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer())
      // embed array buffer of font into PDF
      const customFont = await pdfDoc.embedFont(fontBytes);

      // Modify the PDF.
      const firstPage = pdfDoc.getPages()[0];
      const {height} = firstPage.getSize();
      const date = this.invoiceDate(invoice.sold_at)

      const product = (await products
        .getStock(invoice.stock_number)).data;

      const purchase_price = this.formatCostInPence2dp(product.purchase_price, invoice.currency)
      const consignor_profit = this.formatCostInPence2dp(product.consignor_profit, invoice.currency)
      const total = this.formatCostInPence2dp(product.purchase_price + product.consignor_profit, invoice.currency)
      const investor = await accounts.get(this.inventory[0].account_id);
      const max_description_chars = 67;
      const margin_px = 10;
      let exported = 0;
      if (product.is_bag_exported === 1) {
        exported = 1;
      }
      firstPage.drawText(investor.data.user.name, {
        x: 350,
        y: 610,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.user.address_line_one, {
        x: 350,
        y: 595,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.user.city, {
        x: 350,
        y: 580,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(investor.data.user.postcode.toUpperCase(), {
        x: 350,
        y: 565,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });


      firstPage.drawText(this.inventory[0].account_id + "/" + invoice.sales_number.toString(), {
        x: 121,
        y: 657,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(date.toString(), {
        x: 91,
        y: 673,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText('1', {
        x: 91,
        y: 426,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText('1', {
        x: 91,
        y: 406,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      const invoice_description = invoice.name.toString();
      const invoice_chunks = invoice_description.match(new RegExp('.{1,' + max_description_chars + '}', 'g'));

      let linues_counter = 0;
      for (const invoice_chunk of invoice_chunks) {
        firstPage.drawText(invoice_chunk.trim(), {
          x: 136,
          y: 446 - (margin_px * linues_counter),
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        linues_counter++;
      }



      firstPage.drawText("REFUND STOCK BAG (INVOICE: " + invoice.stock_number + ")", {
        x: 136,
        y: 426,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText("COMMISSION FROM SALES (SOLD INVOICE: " + invoice.sales_number + ")", {
        x: 136,
        y: 406,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      if (exported) {
        firstPage.drawText("EXPORTED", {
          x: 136,
          y: 386,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      } else {
        firstPage.drawText("VAT: THIS INVOICE DOES NOT GIVE RISE TO INPUT CREDIT", {
          x: 136,
          y: 346,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),

        });
        firstPage.drawText("OF VAT UNDER THE UK’s MARGIN RULE", {
          x: 136,
          y: 336,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),

        });

      }

      firstPage.drawText(purchase_price.toString(), {
        x: 485,
        y: 426,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      firstPage.drawText(consignor_profit.toString(), {
        x: 485,
        y: 406,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      if (exported) {
        firstPage.drawText("VAT:", {
          x: 421,
          y: 309,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText("0%", {
          x: 485,
          y: 309,
          size: 8.1,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }

      // Total
      firstPage.drawText(total.toString(), {
        x: 485,
        y: 289,
        size: 8.1,
        font: customFont,
        color: rgb(0, 0, 0),

      });

      // Save the modified PDF to a variable (`pdfBytes`).
      // Now, you can use `pdfBytes` for any additional operations (e.g. downloading the modified PDF).
      return await pdfDoc.save();
    },

    async handleButtonClick(invoice, type = 'straightforward') {
      let invoice_band_type = 'luxury'
      const acc = await accounts.get(this.inventory[0].account_id);
      const investor = await investors.get(acc.data.user_id);
      if(investor.data.brand_id === 1 && investor.data.brand_changed_at < invoice.purchased_at)
      {
        invoice_band_type = 'maia'
      }
      try {
        const pdf_template_url = `/Invoice_template_${invoice_band_type}_${type}.pdf`;
        const pdfBytes = type === 'straightforward' ? await this.viewStraightforwardInvoicePdf(invoice, pdf_template_url): await this.viewReverseInvoicePdf(invoice, pdf_template_url);
        this.downloadPdf(pdfBytes);
      } catch (error) {
        console.error('Error modifying PDF:', error);
      }
    },
    downloadPdf(pdfBytes) {

      const blob = new Blob([pdfBytes], {type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = '/invoice_' + this.inventory[0].stock_number.toString() + '.pdf';
      link.click();
    },
  }
};

</script>
