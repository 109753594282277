<template>
  <div>
    <v-btn dark depressed color="light" @click.prevent="openModal"
      >View Consignment Notice</v-btn
    >

    <v-dialog v-model="showModal" max-width="600">
      <v-card>
        <v-card-title class="text-h5 mb-4">Consignment Notice</v-card-title>

        <v-card-text>
          <div class="mb-1"><b>Consignee:</b> Maia Luxury Limited</div>
          <div class="mb-1"><b>Consigner's Name:</b> {{ user.name }}</div>
          <div class="mb-5"><b>Date:</b> {{ todaysDate }}</div>

          <p>
            You the Consigner have left with me the Consignee the following
            items for sale to my clients:
          </p>

          <p>Description of items:</p>
          <ol>
            <li
              v-for="item in currentInventory"
              :key="`description-${item.id}`"
            >
              {{ item.name }}
            </li>
          </ol>

          <p class="mt-4">
            We agree that the value assigned to each for Insurance purposes is:
          </p>
          <ol>
            <li v-for="item in currentInventory" :key="`insurance-${item.id}`">
              {{ item.name }} -
              {{
                formatCostInPence2dp(item.expected_sale_price, item.currency)
              }}
            </li>
          </ol>

          <p class="mt-4">
            We agree that, in the event of damage or theft occurring that is
            covered under our policy of Insurance with XL Insurance Co. Ltd. we
            are responsible to you.
          </p>
          <p class="mt-4">
            Nicola Morris
            <br />
            Director
            <br />
            Maia Luxury Limited
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="showModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Display from "../../mixins/Display";
export default {
  props: ["currentInventory", "account"],
  mixins: [Display],
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),

    todaysDate() {
      return moment().format("Do MMMM YYYY");
    },
  },
};
</script>

<style>
</style>