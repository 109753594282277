<template>
  <div class="text-center pt-10 no-accounts">
    <h1>Welcome to MAIA!</h1>

    <p>
      Your account is currently being setup. Check back in a few minutes!
    </p>
  </div>
</template>

<script>
const TYPES = [
  {
    value: 0,
    label: "Discretionary",
    description: "We'll do everything on your behalf",
  },
  {
    value: 1,
    label: "Part-Discretionary",
    description: "Require some approval",
  },
  {
    value: 2,
    label: "Approval Required",
    description: "Always require approval",
  },
];

export default {
  data() {
    return {
      showModal: false,

      typeRules: [(v) => (v !== null) || "Please specify your desired account type"],
      amountRules: [(v) => !!v || "Please specify how much you would like to invest"],

      form: {
        type: 0,
        amount: ''
      },
      typeOptions: _.map(TYPES, type => {
        return {
          value: type.value,
          text: type.label + ' (' + type.description + ')'
        }
      }),

      isSaving: false,
      submitted: false
    }
  },

  methods: {
    getStarted() {
      this.showModal = true;
    },

    attemptGetStarted() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.submitGetStarted();
      }
    },

    submitGetStarted() {
      let vm = this;
      vm.isSaving = true;
      window.setTimeout(function () {
        vm.submitted = true;
        vm.isSaving = false;
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
.no-accounts {
  .welcome-video {
    margin-top: 40px;
  }

  .get-started {
    margin-top: 35px;
  }
}

.intro {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.fw-bold {
  font-weight: 700 !important;
}
</style>